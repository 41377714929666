<template>
  <div>
    <v-row justify="center">
      <h1>Integrer med CVR i dag</h1>
    </v-row>

    <v-row justify="center">
      <p class="pricing-paragraph ml-4 mr-4 mt-5">
        Få omgående adgang til data fra CVR-registret med et
        <a rel="noopener noreferrer" target="_blank" :href="docsUrl">live-opdateret, simpelt og veldokumenteret REST
          API</a>, og undgå ansøgninger, ventetider og integrationer med Virks sparsomt
        dokumenterede Elastic Search endpoints!
      </p>
    </v-row>
    <v-row justify="center" v-if="!isLoggedIn && !hasActiveSubscription" class="mt-5 mb-7">
      <router-link :to="{ name: 'signup' }" class="no-decoration">
        <v-btn>Opret konto og prøv 30 dage gratis! </v-btn>
      </router-link>
    </v-row>
    <v-row justify="center" class="mt-4">
      Månedlig betaling
      <v-switch class="subscription-type-switch" v-model="showPriceYearly" color="grey" hide-details></v-switch>
      Årlig betaling
      <v-badge v-if="showPriceYearly" content="Spar 2 måneder!"></v-badge>
    </v-row>
    <v-row class="mb-6" justify="center">
      <v-col v-for="product in products" :key="product.name" style="min-width: 300px; max-width: 300px" class="p-5">
        <v-spacer />
        <v-card>
          <div v-if="product.popular" class="popular">
            <div class="popular-text">Anbefalet</div>
          </div>
          <v-card-title class="headline">
            <p class="text-h4 text--primary">{{ product.name }}</p>
          </v-card-title>

          <v-card-text>
            {{ product.description }}
          </v-card-text>

          <v-card-title>
            <div class="text-subtitle-1">
              <span v-if="showPriceYearly">
                {{ product.priceYearlyPerMonth.toLocaleString() }}</span>
              <span v-if="!showPriceYearly">
                {{ product.priceMonthly.toLocaleString() }}
              </span>
              kr./måned ekskl. moms
            </div>
          </v-card-title>

          <v-divider class="mx-4"></v-divider>

          <v-list>
            <v-list-item class="features-list-item" v-for="feature in product.features" v-bind:key="feature.id">

              <v-icon v-if="feature.missing" color="red" class="features-checkmark">mdi-close</v-icon>
              <v-icon v-else color="green" class="features-checkmark">mdi-check</v-icon>
              <span v-if="feature.href != null"><a :href="feature.href">{{ feature.text }}</a></span>
              <span v-else> {{ feature.text }} </span>
            </v-list-item>
          </v-list>
          <v-spacer />
          <div v-if="isLoggedIn">
            <v-card-actions v-if="!hasActiveSubscription">
              <v-btn color="blue" text @click="purchase(product, showPriceYearly)">
                <span v-if="!hasHadTrial">Prøv 30 dage gratis</span>
                <span v-if="hasHadTrial">Påbegynd abonnement</span>
              </v-btn>
            </v-card-actions>
            <v-card-actions v-if="hasActiveSubscription">
              <v-btn v-if="hasHadTrial" color="blue" text @click="updateSubscription()">
                Opdater abonnement
              </v-btn>
              <v-btn v-if="!hasHadTrial" color="blue" text @click="purchase(product, showPriceYearly)">
                Prøv 30 dage gratis
              </v-btn>
            </v-card-actions>
            <v-spacer />
          </div>
        </v-card>
        <v-spacer />
      </v-col>
    </v-row>
    <v-row justify="center">
      <p class="pricing-paragraph ml-4 mr-4 mt-n6 mb-8">
        Hvis I har behov, der ikke passer ind i ovenstående abonnementer, er I
        meget velkomne til at
        <a rel="noopener noreferrer" target="_blank" href="mailto:kontakt@cvr.dev">kontakte os</a>. Så kan vi forsøge
        at finde en model, der passer til jeres behov.
      </p>
    </v-row>
  </div>
</template>

<script>
import BackendClient from "@/clients/backend";
import { mapGetters } from "vuex";
import config from "@/config.js";

export default {
  computed: {
    ...mapGetters("cognito", ["isLoggedIn"]),
  },
  data() {
    const docsRaaData = `${config.DocsUrl}#tag/Ra-data-fra-CVR`;
    const docsEjerskabshierarki = `${config.DocsUrl}#tag/Ejerskabshierarki`;

    return {
      docsUrl: config.DocsUrl,
      showPriceYearly: false,
      hasActiveSubscription: false,
      hasHadTrial: false,
      products: [
        {
          name: "Starter",
          description:
            "For virksomheder og udviklere, der vil prøve kræfter med data fra CVR",
          priceMonthly: config.ProductStarterPriceMonthly,
          priceMonthlyId: config.ProductStarterPriceMonthlyId,
          priceYearlyPerMonth: config.ProductStarterPriceYearlyPerMonth,
          priceYearlyId: config.ProductsStarterPriceYearlyId,
          features: [
            { id: 1, text: "API med rå-data fra CVR", href: docsRaaData},
            { id: 2, text: "API med ejerskabshierarki", href: docsEjerskabshierarki, missing: true},
            { id: 3, text: `${config.ProductsStarterRequestsPerMonth.toLocaleString()} requests/måned`},
            { id: 4,text: `${config.ProductsStarterRequestsPerSecond.toLocaleString()} requests/sekund`},
        ],
        },
        {
          name: "Business",
          description:
            "For mindre virksomheder, der regelmæssigt benytter data fra CVR",
          popular: true,
          priceMonthly: config.ProductBusinessPriceMonthly,
          priceMonthlyId: config.ProductBusinessPriceMonthlyId,
          priceYearlyPerMonth: config.ProductBusinessPriceYearlyPerMonth,
          priceYearlyId: config.ProductsBusinessPriceYearlyId,
          features: [
            { id: 1, text: "API med rå-data fra CVR", href: docsRaaData},
            { id: 2, text: "API med ejerskabshierarki", href: docsEjerskabshierarki},
            { id: 3, text: `${config.ProductsBusinessRequestsPerMonth.toLocaleString()} requests/måned`},
            { id: 4, text: `${config.ProductsBusinessRequestsPerSecond.toLocaleString()} requests/sekund`},
          ],
        },
        {
          name: "Business Pro",
          description:
            "For virksomheder, der ofte benytter CVR-data i deres vigtigste processer",
          priceMonthly: config.ProductBusinessProPriceMonthly,
          priceMonthlyId: config.ProductBusinessProPriceMonthlyId,
          priceYearlyPerMonth: config.ProductBusinessProPriceYearlyPerMonth,
          priceYearlyId: config.ProductsBusinessProPriceYearlyId,
          features: [
            { id: 1, text: "API med rå-data fra CVR", href: docsRaaData },
            { id: 2, text: "API med ejerskabshierarki", href: docsEjerskabshierarki },
            { id: 3, text: `${config.ProductsBusinessProRequestsPerMonth.toLocaleString()} requests/måned`},
            { id: 4, text: `${config.ProductsBusinessProRequestsPerSecond.toLocaleString()} requests/sekund`},
          ],
        },
      ],
    };
  },

  async mounted() {
    this.backendClient = new BackendClient(this.$store);

    if (this.isLoggedIn) {
      const output = await this.backendClient.listActiveProductAccessPeriods();
      this.hasActiveSubscription = output.active_product_access_periods.length > 0;
      this.hasHadTrial = output.has_had_trial;
    }
  },

  methods: {
    async purchase(product, yearlyPrice) {
      let priceId = product.priceMonthlyId;
      if (yearlyPrice) {
        priceId = product.priceYearlyId;
      }

      window.location.href = await this.backendClient.createCheckoutSession(priceId);
    },

    async updateSubscription() {
      window.location.href = await this.backendClient.createCustomerPortalSession();
    },
  },
};
</script>

<style>
.card-container {
  padding: 10px;
}

.features-list-item {
  min-height: auto;
}

.popular {
  position: absolute;
  left: auto;
  top: 1%;
  right: 3%;
  bottom: auto;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 56px;
  height: 56px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-style: solid;
  border-width: 2px;
  border-color: #2196f3;
  border-radius: 50% !important;
}

.popular-text {
  margin-bottom: 0px;
  color: #2196f3;
  font-size: 10px;
  font-weight: 400;
}

.features-checkmark {
  margin-right: 5px;
}

.subscription-type-switch {
  margin-top: 0 !important;
  padding-left: 10px;
  padding-right: 10px;
}

.pricing-paragraph {
  max-width: 800px;
  min-width: 300px;
}

.no-decoration {
  text-decoration: none;
}
</style>